import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material"; // Sorting icons
import ManageContext from "../../context/manage/manage.context";
import styles from "../../styles/Manage.module.css";

interface AllTemplateDocumentsTableProps {
  allDocs: any[];
}

export const AllTemplateDocumentsTable: React.FC<
  AllTemplateDocumentsTableProps
> = ({ allDocs }) => {
  const navigate = useNavigate();
  const { setCurrentDocumentHeaderId } = React.useContext(ManageContext);
  const [allDocumentData, setAllDocumentData] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [sortDirection, setSortDirection] = useState<boolean>(true); // true for ascending, false for descending

  const baseURL = process.env.REACT_APP_API_URL;
  const headerKey = process.env.REACT_APP_API_HEADER_KEY;

  useEffect(() => {
    const updatedDocs = allDocs?.map((doc) => ({
      Subject: doc?.templateName,
      TotalDocument: doc?.totalDocuments,
      SentOn: doc?.sentOnMail,
      Status: doc?.signerStatus,
      LastChanges: doc?.lastUpdated,
      HeaderId: doc?.templateHeaderId,
      senderStatus: doc?.senderStatus,
      templateDescription: doc?.templateDescription,
    }));
    setAllDocumentData(updatedDocs);
  }, [allDocs]);

  const handleSign = async (templateHeaderId: string, templateName: string) => {
    try {
      const res = await fetch(
        `${baseURL}/template/use-template-as-document/${sessionStorage.getItem(
          "userId"
        )}/${sessionStorage.getItem("companyId")}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            key: `${headerKey}`,
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
          body: JSON.stringify({
            templateHeaderId,
            templateName,
          }),
        }
      );

      if (!res.ok) {
        throw new Error(`API Error: ${res.status} ${res.statusText}`);
      }

      const data = await res.json();
      setCurrentDocumentHeaderId(data?.documentHeaderId);

      enqueueSnackbar("Template successfully used as a document!", {
        variant: "success",
      });
      navigate("/documentDetails");
    } catch (err) {
      console.error("Failed to use template as document", err);
      enqueueSnackbar(`Failed to use template: Unexpected error occurred.`, {
        variant: "error",
      });
    }
  };

  const handleSort = () => {
    const sortedData = [...allDocumentData].sort((a, b) => {
      if (sortDirection) {
        return moment(a.LastChanges).isBefore(b.LastChanges) ? -1 : 1;
      } else {
        return moment(a.LastChanges).isBefore(b.LastChanges) ? 1 : -1;
      }
    });
    setAllDocumentData(sortedData);
    setSortDirection(!sortDirection);
  };

  return (
    <>
      {allDocumentData.length === 0 ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "20px",
            fontSize: "18px",
            color: "#666",
          }}
        >
          No documents available.
        </div>
      ) : (
        <>
          <TableContainer
            // component={Paper}
            style={{ maxHeight: "65vh", overflow: "auto" }}
            className="shadow-md sm:rounded-lg"
          >
            <Table
            //  stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold", width: "30%" }}>
                    SUBJECT
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", width: "35%" }}>
                    DESCRIPTION
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", width: "20%" }}>
                    LAST CHANGED
                    <IconButton onClick={handleSort} size="small">
                      {sortDirection ? <ArrowUpward /> : <ArrowDownward />}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      width: "15%",
                    }}
                  >
                    ACTIONS
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allDocumentData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{ wordBreak: "break-word", fontSize: "14px" }}
                    >
                      {row.Subject}
                    </TableCell>
                    <TableCell
                      style={{ wordBreak: "break-word", fontSize: "14px" }}
                    >
                      {row.templateDescription}
                    </TableCell>
                    <TableCell
                      style={{ wordBreak: "break-word", fontSize: "14px" }}
                    >
                      {moment(row.LastChanges).format("D MMM YY, h:mm A")}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={styles.btn}
                        onClick={() => handleSign(row?.HeaderId, row?.Subject)}
                      >
                        Use
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};
